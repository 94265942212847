.main {
  width: 100%;
  height: 100%;
}

.main-row {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}
.main-sort {
  display: flex;
  flex-direction: row;
}
.main-box {
  margin: 0 auto;
}
.sort-row {
  display: none;
 
}
.main-decor {
  position: relative;
}
.main-decor-img {
  position: absolute;
  transform: translate(-2%, -70%);
  max-width: 100%;
  z-index: 100;
}
.sort-name {
  color: #272727;
  font-weight: 300;
}
.popular {
  color: #656565;
  font-weight: 300;
  background-color: white;
  border-radius: 3px;
  border: none;
}
.new {
  color: #656565;
  font-weight: 300;
  border-radius: 3px;
  background-color: white;
  border: none;
}
.popular:hover,
.new:hover {
  color: #33282c;
  background-color: #f2ca5b;
}
.wrap-main-line {
  border-bottom: 3px solid #ebebeb;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 5px;
}
.news {
  display: flex;
  flex-direction: row;
  text-align: center;
}
.main-name {
  text-transform: uppercase;
  color: #272727;
  font-weight: 800;
  margin-left: 10%;
  margin-top: 17%;
}
.name-noties-box {
  width: 105px;
  height: 25px;
  margin-left: 2px;
  border-bottom: 1px dotted #30b5d8;
  font-size: 18px;
  color: #30b5d8;
  font-weight: 300;
}
.name-noties-box:hover {
  color: #103c47;
  border-bottom: 1px dotted #103c47;
}
.main-select{
  
  width: 250px;
  background-color: #ebebeb;
  color: #30b5d8;
  border: none;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.main-choice-cont {
  display: flex;
  justify-content: flex-end;
}
.img-btn-box {
  display: flex;
  flex-direction: column;
}
.wrap-main-cont {
  /* padding-top: 30px;
  padding-bottom: 30px; */
  margin: 20px auto 0;
}

.cont-img1 {
  grid-area: "f1";
  grid-column: 1 / 3;
  grid-row: 1;
  position: relative;

}
.cont-img1 img,
.cont-img2 img,
.cont-img3 img,
.cont-img4 img,
.cont-img5 img,
.cont-img6 img,
.cont-img7 img {
    background-image: cover;
    width: 100%;
    height: 100%;
}
.main-cont-line {
  width: 100px;
  height: 5px;
  background-color: #fec230;
  position: absolute;
}
.main-cont-line1 {
  top: 170px;
  left: 40px;
}
.caption-block {
  width: 80%;
  height: 30%;
  background-color: rgba(150, 49, 75, 0.8);
  padding: 10px 40px;
  transform: skewX(-5deg);
  position: absolute;
  top: 190px;
  left: 40px;
  font-style: italic;
}
.caption {
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
}
/* .caption:hover,
.caption2:hover {
  color: #000000;
 background-color: #ebebeb75;
} */
.cont-img2 {
  grid-area: "f2";
  grid-column: 3;
  grid-row: 1;
  position: relative;
}


.caption-block2 {
  width: 98%;
  height: 100px;
  background-color: #ffffff00;
  position: absolute;
  top: 240px;
  left: 20px;
}
.main-cont-line2 {
  top: 230px;
  left: 20px;
}
.caption2 {
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: 300;
  width: 90%;
  text-transform: uppercase;
}
.cont-img3 {
  grid-area: "f3";
  grid-column: 4;
  grid-row: 1;
  position: relative;
}



.img3 {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.img7 {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img4 {
  grid-area: "f4";
  grid-column: 1;
  grid-row: 2;
  position: relative;
}



.cont-img5 {
  grid-area: "f5";
  grid-column: 2;
  grid-row: 2 / 4;
  position: relative;
}


.main-cont-line5 {
  top: 83%;
  left: 20px;
}
.caption5 {
  font-weight: 600;
}
.caption-block5 {
  width: 98%;
  height: 100px;
  background-color: #ffffff00;
  position: absolute;
  top: 85%;
  left: 20px;
}
.caption-block7 {
  width: 95%;
  height: 100px;
  background-color: #ffffff00;
  position: absolute;
  top: 67%;
  left: 20px;
}
.cont-img6 {
  grid-area: "f6";
  grid-column: 3;
  grid-row: 2;
  position: relative;
}



.cont-img7 {
  grid-area: "f9";
  grid-column: 3 / 5;
  grid-row: 3;
  position: relative;
}



.main-cont-line7 {
  top: 62%;
  left: 20px;
}
.comment-img6,
.comment-img7 {
background-color: #f3efdb;
}
.comment-img6 {
  grid-area: "f7";
  grid-column: 4;
  grid-row: 2;
  position: relative;
}



.comment-img6-name {
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #000000;
  font-weight: 400;
  margin-top: 50px;
  margin-left: 20px;
  width: 80%;
}
.img6-name-b {
  font-weight: 700;
}
.comment-img6-text {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #000000;
  font-weight: 300;
  margin-left: 20px;
  width: 80%;
}
.main-cont-line3 {
  top: 30px;
  left: 20px;
}
.comment-img7 {
  grid-area: "f8";
  grid-column: 1;
  grid-row: 3;
  position: relative;
}



.triangle-top {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -40px;
  left: 20px;
  border: 20px solid transparent;
  border-bottom: 20px solid #f3efdb;
}
.triangle-left {
  height: 20px;
  width: 20px;
  position: absolute;
}
.btn-all {
  text-decoration:double;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: rgba(239, 239, 239, 0);
  font-size: 24px;
  line-height: 24px;
  color: #103c47;
  font-weight: 300;
border: none !important;
}
.wrap-more {
  display: flex;
  justify-content: center;
}
.more:hover {
  color: #272727;
}
.css-g1d714-ValueContainer, .css-yk16xz-control {
  height: 50px;
}


@media(min-width:992px) {
  .sort-name {
    margin-top: 40px;
  }

  .main-name {
    font-size: 40px;
  }

  .wrap-main-line {
max-height: 115px;

  }

  .main-choice {

    font-size: 25px;

    width: 40%;
    height: 50px;

    margin-top: 5%;

    padding-left: 30px;
    padding-right: 30px;

  }

  .popular {

    margin-top: 26px;
  }

  .new {

    margin-top: 26px;
  }

  

}


@media(min-width:1650px) {
  

  .main-choice {
    margin-right: -13.9%;
  }

  .wrap-main-cont {

    display: grid;
    grid: "f1 f1 f2 f3"320px 
    "f4 f5 f6 f7"330px 
    "f8 f5 f9 f9"320px 
    /400px 400px 290px 290px;
  
  }

 

  
  .sort-name {
    font-size: 25px;


  }

  .popular {
    font-size: 25px;
    line-height: 24px;

    width: 208px;
    height: 50px;

  }

  .new {
    font-size: 25px;
    line-height: 24px;

    width: 148px;
    height: 50px;

  }

  .caption2 {
    font-size: 24px;
    line-height: 36px;
  }

  .comment-img6-name {

    margin-bottom: 30px;

  }

  



  
}
@media(max-width:1650px) {
 

.main-choice {
margin-right: -7.5%;
}
.wrap-main-cont {
  padding-top: 30px;
  /* padding-bottom: 30px ; */
  margin: 0 auto;
  display: grid;
  grid:"f1 f1 f2 f3" 320px
       "f4 f5 f6 f7" 330px
       "f8 f5 f9 f9" 320px
       /300px 300px 190px 190px
  ;
  
}



.sort-name {
  font-size: 20px; 
  
 
}

.popular {
font-size: 20px;
line-height: 20px;

width: 100%;
height: 40px;

}
.new {
font-size: 20px;
line-height: 20px;

width: 100%;
height: 40px;

}
.caption2 {
  font-size: 15px;
  line-height: 30px;
  width: 88%;
}
.caption5 {
  max-width: 250px;
}
.caption7 {
  max-width: 250px;
}
.comment-img6-name {

margin-bottom: 15px;

}


}
@media(max-width:1200px) {

.main-choice {
margin-right: -6%;
}



}
@media(max-width:992px) {
   .wrap-main-line {
    flex-direction: column;
    align-items: center;
  }
  .wrapper{ 
  display: flex;
  flex-direction: row;
 max-height: 60px;
}

  .css-2b097c-container {
    width: 150px;
    margin-bottom: 10px;
}
.sort-name {
  font-size: 18px; 
   margin-top: 30px; 
}
.main-name {
font-size: 30px;
margin-left: 0;
}
.name-noties-box {
  margin-top: 10px;
}
.main-decor-img {
  transform: translate(-2%, -93%);
}
.wrap-main-line {
 min-height: 60px;
 justify-content: center;
}
.main-choice {
font-size: 18px;
width: 150px;
height: 50px;
margin-top: 0%;
margin-left: -70%;
}
.main-choice-cont {
  padding: 20px 60px 0px;
  margin: 0 auto;
  grid-template: 1fr/minmax(150px, 1fr) minmax(150px, 1fr);
}

.popular {
font-size: 18px;
line-height: 20px;
width:100%;
height: 40px;
  margin-top: 20px; 
}
.new {
font-size: 18px;
line-height: 20px; 
 width: 100%;
height: 40px;
  margin-top: 20px; 
}
.triangle-left {
  top: 40px;
  right: -40px;
border: 20px solid transparent;
border-left: 20px solid #f3efdb;
}


  .wrap-main-cont {
  padding-top: 1rem;
  margin: 0 auto;
  display: grid;
grid: "f1 f1" 320px
      "f2 f3" 320px
      "f5 f6" 330px
      "f5 f8" 330px
      "f7 f4" 330px
      "f9 f9" 320px
      /360px 360px
  }
  .cont-img1 {
  grid-column: 1/3;
  grid-row: 1;
}
 .cont-img1 img {
   width: 100%;
  height: 100%;
  background-size: cover;
}
.caption-block {
   width: 70%;
 
}
.cont-img2 {
  grid-column: 1/2;
  grid-row: 2;
}
.cont-img2 img{
  width: 100%;
  height: 100%;
}
.cont-img3 { 
  grid-column: 2/3;
  grid-row: 2;
}
.cont-img4 {
  grid-column: 2/3;
  grid-row: 5;
}
.cont-img4 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}


.cont-img5 {
 grid-column: 1/2;
  grid-row: 3 / 5;
}
.cont-img5 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img6 {
  grid-column: 2/3;
  grid-row: 3;
}
.cont-img6 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img7 {
  grid-column: 1 / 3;
  grid-row:6;
}
.cont-img7 img{

    width: 100%;
  height: 100%;
  background-size: cover;
}
.comment-img6 {
  grid-column: 1/2;
  grid-row: 5;
}
.comment-img7 {
   grid-column: 2/3;
   grid-row: 4;
}
}
@media(max-width:767px) {
  
 
 .wrap-main-cont {
  padding-top: 1rem;
  margin: 0 auto;
  display: grid;
grid: "f1" 160px
      "f2" 220px
      "f3" 310px
      "f5" 400px
      "f6" 190px
      "f8" 240px
      "f4" 200px
      "f7" 280px
      "f9" 280px
      /320px   
  }
 .cont-img1 {
  grid-column: 1;
  grid-row: 1;
}
 .cont-img1 img {
   width: 100%;
  height: 100%;
  background-size: cover;
}
.caption-block {
   width: 70%;
}
.cont-img2 {
  grid-column: 1;
  grid-row: 2;
}
.cont-img2 img{
  width: 100%;
  height: 100%;
}
.cont-img3 { 
  grid-column: 1;
  grid-row: 3;
}
.cont-img4 {
  grid-column: 1;
  grid-row: 7;
}
.cont-img4 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img5 {
 grid-column: 1;
  grid-row: 4;
}
.cont-img5 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img6 {
  grid-column: 1;
  grid-row: 5;
}
.cont-img6 img{
  width: 100%;
  height: 100%;
  background-size: cover;
}
.cont-img7 {
  grid-column: 1;
  grid-row:8;
}
.cont-img7 img{
    width: 100%;
  height: 100%;
  background-size: cover;
}
.comment-img6 {
  grid-column: 1;
  grid-row: 6;
}
.comment-img7 {
   grid-column: 1;
   grid-row: 9;
}
.triangle-top {
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: -2.5rem;
  left: 16rem;
  z-index: 100;
 border: 20px solid transparent;
 border-bottom: 20px solid #f3efdb;
}
.triangle-left {
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: -2.5rem;
  left: 15rem;
border: 20px solid transparent;
border-bottom: 20px solid #f3efdb;
}

.wrap-main-cont {
  justify-content: center;
}

.sort-name {
  font-size: 14px; 
 margin-left: -25px;
}
.popular {
font-size: 14px;
line-height: 14px;
 margin-left: -25px;
width: 95px;
height: 30px;
}
.new {
font-size: 14px;
line-height: 14px;
 margin-left: -15px;
width: 55px;
height: 30px;
}




.more {
  width: 250px;
    height: 60px;
    font-size: 14px;
}

.main-name {
    font-size: 25px;
    
}
.name-noties-box {
    /* width: 65px; */
    height: 25px;
    /* margin-left: 2px; */
   /* margin-left: -10px; */
   margin: 0 auto;
   margin-top: 10px;
   justify-items: flex-end;
    font-size: 10px;
}
.main-choice {
    margin-left: -109px;
    font-size: 10px;
   width: 70px;
    margin-top: 5%;
}
.main-decor-img, .decor-none {
  display: none;
}

.more {
    margin-top: 10px;
    margin-bottom: 10px;
}

.caption-block7 {
    width: 310px; 
}
.caption-block5 {
    width: 310px;   
}
.main-choice {
    margin-left: 0px;
  
    margin-top: 0%;
}



}
@media(max-width:475px) {
  .main-choice-cont {
    display: none !important;  
}

.main-dots {
    justify-self: center;
}
 

}