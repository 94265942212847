* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.header {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
 background-color: #ffffff;
  padding: 15px 0;
  z-index: 1000;
}

.navigation {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-link {
  text-decoration: none;
  text-transform: uppercase;
  color: #939699;

  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

button {
  cursor: pointer;
}

.button {
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  margin: 10px 20px;
 display: flex;
 align-items: center;
 justify-content: center;
  position: relative;
  background: #ffffff00;
  border:none;
  color: #7045ff;
    font-weight: 700;
    font-size: 16px;
 
 
  }
.button  svg {
    height: 40px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 100%; 
    
  }
  
.button  rect {
    fill: none;
    stroke: #7045ff;
    stroke-width: 3;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  
  }


.button:hover {
  background: #ffffff00;
  /* font-weight: 500; */
  /* letter-spacing: 1px; */
   
  }
.button:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }












.button-icon,
.button-price {
  margin-right: 10px;

}

.button-icon {
  margin-left: 10px;
}

.button-icon:last-child {
  margin-right: 0;

}

.cart-count {
  margin-left: 10px;
}



.long-goods {
  margin-top: 90px;
  display: block;
}



.slide {
  padding-top: 90px;
  padding-bottom: 110px;
  min-height: 568px;
}

.slide-1 {
  background: #B4D1E0 url('../img/slide-image-1.png') no-repeat top right 30%;
}

.slide-2 {
  background: #b9e0b4 url('../img/slide-image-1.png') no-repeat top right 30%;
}

.label {
  background-color: #3DC47E;
  border-radius: 3px;
  padding: 5px 12px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
}

.slide-title {
  color: #262D33;
  font-size: 55px;
  line-height: 70px;
  
  margin-top: 20px;
  margin-bottom: 15px;
}

.slide-description {
  font-size: 18px;
  line-height: 30px;
  color: #4B5157;
  max-width: 335px;
  margin-bottom: 40px;
}

.slider {
  position: relative;
}

.slider-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 9;
}

.slider-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.card {
  min-height: 370px;
  border-radius: 10px;
  padding-top: 60px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 80px;
}


.card-1 {
  background: #E3E2E3 url('../img/card-1.jpg') no-repeat bottom 50% right;
  background-size: cover;
}

.card-2 {
  background: #000 url('../img/card-2.jpg') no-repeat top right;
  background-size: cover;
}

.card-3 {
  padding-top: 35px;
  background: #dbd0c7 url('../img/card-6.jpg') no-repeat bottom 50% right;
  background-size: cover;
}

.card-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 35px;
  padding-left: 25px;
  background: #d0b29a url('../img/card-4.jpg') no-repeat bottom 50% right;
  background-size: cover;
}
.special-offers {
  padding-top: 4rem !important;
}
.goods-card-btn {
  display: block;
  width: 100px;
  margin: auto auto 0;
}

.goods-card-btn .button-price {
  margin: 0;
}

.card-title {
  color: #262D33;
  font-size: 25px;
  line-height: 30px;
 
  max-width: 222px;
  margin: 0;
}

.card-title.large {
  max-width: 420px;
  font-size: 40px;
  line-height: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.card-text {
  max-width: 176px;
  color: #4B5157;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
}

.card-text.large {
  max-width: 320px;

}

.card-price {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
}

.text-light {
  color: rgb(255, 255, 255);
}

.section-title {
  
  font-size: 25px;
  line-height: 30px;
  color: #262D33;
}

.more {
  text-decoration: none;
  color: #262D33;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  /* padding: 10px; */
  justify-self: center;
  align-self: center;
}
.more:hover {
   color: #7045ff !important;
}
.goods-card {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  padding: 30px 35px 35px;
  min-height: 450px;
}

.goods-card .label {
  position: absolute;
  left: 18px;
  top: 18px;
}

.goods-title {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #262D33;
  font-size: 16px;
  line-height: 25px;

}

.goods-image {
  object-fit: contain;
  height: 190px;
}

.goods-description {
  color: #4B5157;
  font-size: 14px;
  padding: 15px 0;
}

.goods-price {
  color: #262D33;
  margin-top: 30px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 900;
}
.button-four {
  margin-top: auto;
}
.button-bg {
  color: #ffffff !important;
  background: #a99ad8;
}
.button-bg:hover {
   color: #ffffff !important;
 background: #9582d4;
}

.footer-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer {
  background: #7f79d4;
  padding-top: 45px;
  padding-bottom: 40px;
  color: #fff;
}

.footer-menu-item:not(:last-child) {
  margin-right: 40px;
}

.footer-menu-link {
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
}

.social-link {
  text-decoration: none;
  margin-left: 15px;
}

.footer-text {
  color: #E7D9FF;
  font-size: 14px;
  line-height: 20px;
}

.footer-line {
  opacity: 0.5;
}

.top-link {
  text-decoration: none;
  color: #fff;
}

.top-link-text {
  margin-right: 15px;
}

.payment-logo {
  vertical-align: middle;
  margin-right: 25px;
}

.overlay {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay.show {
  display: flex;
}

.modal {
  background-color: #e0e0ff;
  min-width: 600px;
  max-height: 400px;
  padding: 30px;
  overflow: scroll;
  position: relative;

}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  margin-top: 0;
  margin-bottom: 15px;
}

.modal-close {
  position: absolute;
  background-color: transparent;
  right: 15px;
  top: 15px;
  font-size: 24px;
  border: none;
}

.modal-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-input {
  margin-right: 30px;
  height: 40px;
  padding: 10px;
  border-radius: 20px;
  border-style: none;
  margin-bottom: 30px;
}

.cart-table {
  border-collapse: collapse;
  min-width: 540px;
  margin-bottom: 15px;
}

.cart-table th,
.cart-table td {
  text-align: left;
  border: 1px solid black
}

.cart-buy {
  background-color: #28a745;
  border-color: #28a745;
  display: block;
  margin-left: auto;
  height: 40px;
}


.mw-160 {
  max-width: 160px;
}


.col-price {
  width: 100px;
}

.col-minus {
  width: 15px;
}

.col-qty {
  width: 15px;
}

.col-plus {
  width: 15px;
}

.col-total-price {
  width: 120px;
}

.col-delete {
  width: 5px;
}


@media (max-width: 1200px) {
  .slide-1 {
    background-size: cover;
  }

  .card-3 {
    background-size: cover;
  }
}

@media (max-width: 992px) {
  .slider-nav {
    display: none;
  }

  .modal {
    min-width: 400px;
    max-height: 300px;
    padding: 20px;
  }

}

@media (max-width: 576px) {
  .slide-title {
    font-size: 36px;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .card {
    background-position: left top;
  }

  .card-4 {
    background-position: right top;
  }

  .card {
    padding: 30px;
    min-height: 300px;
  }

  .modal {
    min-width: 300px;
    max-height: 400px;
    padding: 20px;
  }

}
