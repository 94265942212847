section.container{
  margin-top: 90px;
}
.item_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.goods_title{
justify-self:center;
}

.artc_art_ico_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
     width: 40px;
  margin: 0 auto;
}
.artc_art_num {
  color: #272727;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.btn_like {
   width: 15px;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
  background-color: #ffffff00 !important;
}
.artc_art_ico_box i {
  text-decoration: none;
  color: #a0a0a0;
}

.artc_dowland_ico {
  width: 15px;
  height: 15px;
}
.img-page {
  margin: 40px 0px;
}
.page-contain {
  padding-top: 30px;
}
.goods-card-btn {
margin-top: 40px;
margin-bottom: 80px;
}
.long-text {
  text-align: center;
  width: 80%;
  font-size: 15px;
  font-weight: 500;
  margin-bottom:10px;
}
.item_container {
  position: relative;
}
.item_container .label {
  position: absolute;
  left: 39%;
  top: 60px;
}
.goods_title {
  margin-bottom: 30px;
}